<template>
  <div class="about">
    <div
      class="container-fluid typeStyle type-fa_wlxljy"
     :style="{backgroundImage: 'url(' + require('@/assets/img/children/bg-gyhc.jpg') + ')' }">
      <div class="container">
        <div class="row moreDes-titBox">
          <h1 class="Tit">关于赑风</h1>
          <ul>
            <li>
              赑风科技，中国网络学历教育服务及智慧教育综合服务提供者。着力于以大数据、云计算、人工智能等信息技术为支撑，创新教育产品、构建智慧教育模式，为用户提供多元化和定制化的教育解决方案。
            </li>
            <li>
              自成立以来，赑风科技全面布局，在网络高等教育、成教信息化、高校信息化、IT教育和企业培训等领域稳步推进，在全国建设标准化学习中心50余家，整合名校课程8000余门。服务院校500余所、企业1200余家、网络学员超2000万。
            </li>
            <li>
              秉承“诚信、创新、双赢、为客户创造价值”的价值观，同时，坚持“快乐学习，人生无限可能”的使命，努力成为学习者喜爱的教育服务机构。
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="container-fluid Wrap bg-white History"
      :style="{backgroundImage: 'url(' + require('@/assets/img/children/bg-History.jpg') + ')' }"
    >
      <div class="container" style="padding: 0">
        <div class="title">
          <h2 class="T">发展历程</h2>
          <i></i>
        </div>
        <div class="mCon clearfix">
          <div class="hidden-s">
            <swiper :options="swiperoption" ref="mySwiper">
              <swiper-slide>
                <dl>
                  <dt>
                    <h4><span>2014</span>年</h4>
                  </dt>
                  <dd>
                    <img
                      src="../../../../assets/img/children/pic2018@2x.png"
                    />
                    <ul>
                      <li>
                        与山东第一医科大学继续教育学院开展大技术合作，与福建省教育厅开展继续教育行业管理合作
                      </li>
                    </ul>
                  </dd>
                </dl>
              </swiper-slide>
              <swiper-slide>
                <dl>
                  <dt>
                    <h4><span>2015</span>年</h4>
                  </dt>
                  <dd>
                    <img
                        src="../../../../assets/img/children/pic2015@2x.png"
                    />
                    <ul>
                      <li>成为教育部产学合作协同育人项目支持企业</li>
                    </ul>
                  </dd>
                </dl>
              </swiper-slide>
              <swiper-slide>
                <dl>
                  <dt>
                    <h4><span>2017</span>年</h4>
                  </dt>
                  <dd>
                    <img
                        src="../../../../assets/img/children/pic2017@2x.png"
                    />
                    <ul>
                      <li>与数十所高校开展平台、资源、招生等多种形式合作</li>
                    </ul>
                  </dd>
                </dl>
              </swiper-slide>
            </swiper>
          </div>
          <div class="main-timeline hidden-m hidden-b isOpen">
					<div class="timeline-box" style="height: 128px;">
						<div class="timeline">
							<div class="timeline-content" style="height: 26px;">
								<span class="date"></span>
								<h2 class="title"><b>2017</b></h2>
								<div class="description">
									<p>成为教育部产学合作协同育人项目支持企业</p>
								</div>
							</div>
						</div>
						<div class="timeline">
							<div class="timeline-content" style="height: 26px;">
								<span class="date"></span>
								<h2 class="title"><b>2015</b></h2>
								<div class="description">
									<p>与山东第一医科大学继续教育学院开展大技术合作，与福建省教育厅开展继续教育行业管理合作</p>
								</div>
							</div>
						</div>
						<div class="timeline">
							<div class="timeline-content" style="height: 26px;">
								<span class="date"></span>
								<h2 class="title"><b>2014</b></h2>
								<div class="description">
									<p>创建高校课程资源合作联盟，成教合作院校达到20余所</p>
								</div>
							</div>
						</div>
					</div>
				</div>
        </div>
      </div>
    </div>
    <div
      class="container-fluid Wrap bg-white honor"
      :style="{backgroundImage: 'url(' + require('@/assets/img/children/bg-honor.jpg') + ')' }">
      <div class="container" style="padding: 0">
        <div class="title">
          <h2 class="T" style="color: #fff">行业殊荣</h2>
          <i></i>
        </div>
        <div class="mCon clearfix">
          <div class="hidden-s">
            <swiper :options="swipersuccess" ref="mySwiper">
              <swiper-slide>
                <div class="honor-item">
                  <div class="honor-bgNum">2004</div>
                  <img
                    src="../../../../assets/img/children/ry2009@2x.png"
                  />
                  <div class="honor-jj">
                    <span>2014年</span
                    ><em>·</em
                    >被中国教育信息化科技博览会评选为“中国教育信息化建设优秀奖”
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="honor-item">
                  <div class="honor-bgNum">2005</div>
                  <img
                      src="../../../../assets/img/children/ry2011@2x.png"
                  />
                  <div class="honor-jj">
                    <span>2015年</span
                    ><em>·</em>被中国教育信息化科技博览会评选为”
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="honor-item">
                  <div class="honor-bgNum">2006</div>
                  <img
                      src="../../../../assets/img/children/ry2013@2x.png"
                  />
                  <div class="honor-jj">
                    <span>2016年</span
                    ><em>·</em>技博览会评选为“中国教育信息化建设优秀奖”
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="main-timeline hidden-m hidden-b isOpen">
					<div class="timeline-box" style="height: 128px;">
						<div class="timeline">
							<div class="timeline-content" style="height: 26px;">
								<span class="date"></span>
								<h2 class="title"><b>2017</b></h2>
								<div class="description">
									<p>荣获中国互联网教育“未来独角兽企业”奖</p>
								</div>
							</div>
						</div>
						<div class="timeline">
							<div class="timeline-content" style="height: 26px;">
								<span class="date"></span>
								<h2 class="title"><b>2018</b></h2>
								<div class="description">
									<p>荣获腾讯评选的“实力教育集团”奖</p>
								</div>
							</div>
						</div>
						<div class="timeline">
							<div class="timeline-content" style="height: 26px;">
								<span class="date"></span>
								<h2 class="title"><b>2019</b></h2>
								<div class="description">
									<p>荣获腾讯网“影响力教育集团”奖</p>
								</div>
							</div>
						</div>
					</div>
				</div>
        </div>
      </div>
    </div>
    <div class="container-fluid Wrap bg-grey contact">
      <div class="container" style="padding: 0">
        <div class="title">
          <h2 class="T">联系我们</h2>
          <i></i>
        </div>
        <div class="mCon clearfix contactUs">
          <div class="contact-list clearfix">
            <div class="xs12 xm4 xb4">
              <i
                class="map"
                :style="{backgroundImage: 'url(' + require('@/assets/img/children/icon-map.png') + ')' }">
              </i>
              <div class="contact-label">地址</div>
              <p>北京市朝阳区方恒国际中心B座</p>
            </div>
            <div class="xs12 xm4 xb4">
              <i
                class="tel"
                :style="{backgroundImage: 'url(' + require('@/assets/img/children/icon-tel.png') + ')' }">
              </i>
              <div class="contact-label">电话</div>
              <p>010-5332 3020</p>
            </div>
            <div class="xs12 xm4 xb4">
              <i
                class="email"
                :style="{backgroundImage: 'url(' + require('@/assets/img/children/icon-email.png') + ')' }">

              </i>
              <div class="contact-label">邮箱</div>
              <p>support@biphon.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperoption: {
        effect: "coverflow",
        grabCursor: true,
        watchSlidesProgress: true,
        centeredSlides: true,
        loop: true,
        loopedSlides: 3,
        slidesPerView: 3,
        coverflowEffect: {
          rotate: 30,
          stretch: 0,
          depth: 60,
          modifier: 0,
          slideShadows: false,
        },
      },
      swipersuccess: {
        effect: "coverflow",
        grabCursor: true,
        watchSlidesProgress: true,
        centeredSlides: true,
        loop: true,
        loopedSlides: 3,
        slidesPerView: 3,
        autoplay: {
          delay: 5000, //自动播放速度
          disableOnInteraction: false, //鼠标移上去时是否还继续播放
        },
        coverflowEffect: {
          rotate: 30,
          stretch: 0,
          depth: 60,
          modifier: 0,
          slideShadows: false,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/header/children/about.scss";
</style>
<style lang="scss">
@import "../../../../assets/css/header/children/index.scss";
</style>
